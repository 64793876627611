<template>
    <div class="container">
        <div class="row btm-margin">
            <div class="col-md-3"></div>
            <div class="col-md-6 tp-margin">
                <h1>Reset password</h1>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <b-form @submit.prevent="ResetPassword">
                    <b-form-group id="input-group-email" label-cols-sm="3" label="Email:" label-for="email">
                        <b-form-input id="email" type="text" v-model="$v.form.email.$model" :state="validateState('email')"></b-form-input>
                        <b-form-invalid-feedback v-if="!$v.form.email.required">
                            Email is required
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.email.verifyMail">
                            Email body is incorrect
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <br/>
                    <b-button type="submit" variant="dark" class="mx-auto w-100">Submit</b-button>

                </b-form>
                <b-alert class="mt-2" v-if="form.submitError" variant="warning" dismissible show>
                    Reset failed: {{ form.submitError }}
                </b-alert>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
    </template>
    

    <script>
    // import axios from 'axios';

    import {
        required,
        email
    } from "vuelidate/lib/validators";
    
    export default {
        name: "Register",
        data() {
            return {
                form: {
                    email: "",
                    submitError: undefined
                },
                errors: [],
                validated: false
            };
        },
        validations: {
            form: {
                email: {
                    required,
                    verifyMail: email
                }
            }
        },
        mounted() {
            // console.log("mounted");
            // console.log($v);
        },
        methods: {
            validateState(param) {
                const {
                    $dirty,
                    $error
                } = this.$v.form[param];
                return $dirty ? !$error : null;
            },
            async ResetPassword() {
                try {

                    const content = {
                        email: this.form.email
                    };
                    const json = JSON.stringify(content);
                    console.log(json); // log the JSON payload

                    this.axios.post(this.$store.state.server_domain + 'forgotPassword', json, this.$store.state.config).then((res) => {
                        console.log("here");
                        console.log(res.data);
                        if (res.data.data && res.data.data.length > 0 && res.data.data != "error") {
                            this.success = 'Check your email for a password reset link.';
                            this.error = null;
                            this.$root.toast("Reset", `Check your email for a password reset link.`, "success");
                        } else {
                            this.$root.toast("Login", `Reset for user ${this.form.email} failed`, "danger");
                        }
                    }).catch((err) => {
                        console.log("here1");
                        this.$root.toast("Reset", `${err.response.data.error}`, "danger");
                    });
                } catch (err) {
                    console.log("here2");
                    this.form.submitError = err.response.data;
                    this.$root.toast("Forgot password", `${err.response.data.error}`, "danger");
                }
            },
            onReset() {
                // console.log("register method called");
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                // console.log("register method go");
                this.ResetPassword();
            }
        }
    };
    </script>
    
    <style>
    .btm-margin {
        margin-bottom: 3%;
    }
    
    .tp-margin {
        margin-top: 3%;
    }
    </style>
    